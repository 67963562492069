import {
    Link as ChakraLink,
    Text,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    others,
    useToast,
    InputGroup,
    InputLeftElement,
    FormLabel
  } from '@chakra-ui/react'

import {IoSettings, IoExit, IoChevronForward, IoBriefcase, IoPerson, IoMail,IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle} from 'react-icons/io5';

import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select,
  useChakraSelectProps
} from "chakra-react-select";

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { resolve } from 'path';
import axios from 'axios';
import { useTranslation } from 'next-i18next'

const InviteUserModal = ({ isOpen , onClose, session }) => {
  const {t} = useTranslation('common')
  
  const [isBrowser, setIsBrowser] = useState(false);
  const [newUser, setNewUser] = useState({username: "", email: "", role: "READER", groups: [], invitationId: "EMPTY"});
  const [errorUserInvite, setErrorUserInvite] = useState(t("inviteUserElement.completeAllFields"));
  const [isLoadingUserInvite, setIsLoadingUserInvite] = useState(false);

  const [groupsListOptions, setGroupsListOptions] = useState([])

  const toast = useToast()
  

  const { isOpen: isRolesOpen, onOpen: onRolesOpen, onClose: onRolesClose } = useDisclosure()

  const api_url = process.env.NEXT_PUBLIC_API_URL

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const loadGroups = async () =>{
    let groupsData;
    try{
      const fetchData = axios.get(`${api_url}/groups`, { 
        headers: { Authorization: `Bearer ${session.accessToken}` }
      });
      const data = await fetchData;

      if(data.status != 403 && data.status != 500){
        groupsData = data.data;
      }
    }catch (ex){
      return
    }
    
    let groupsListTemp = [];
    groupsData.map((singleGroup)=>{
      groupsListTemp.push(
        {
          label: singleGroup.title,
          value: singleGroup._id,
        })
    })
    setGroupsListOptions([...groupsListTemp]);
  
  }


  React.useEffect(() => {
    loadGroups()
  }, [isOpen])

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };
  const userNotification = async (title, subTitle, status) => {
    toast({
      title: title,
      description: subTitle,
      status: status,
      duration: 5000,
      isClosable: true,
      position: 'bottom',
    })
  }

  const createNewUser = async () =>{
    let body = newUser;
    setIsLoadingUserInvite(true)
    const fetchData = await fetch(`${api_url}/users/`, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    
    if(fetchData.status == 201){
        const response = await fetchData.json();
        setIsLoadingUserInvite(false)
        onClose()
        setNewUser({username: "", email: "", role: "READER", invitationId: "EMPTY", groups:[]});
        userNotification(t("inviteUserElement.inviteSuccess"),t('inviteUserElement.inviteDone', { username: body.username}), "success");
    }else{
        const response = await fetchData.json();
        setIsLoadingUserInvite(false)
        if(response.message.length == 1){
            userNotification("Error", response["message"][0], "error");
        }else{
            userNotification("Error", response["message"], "error");
        }
    }
  }


    
  const onChangeText = (key, val) => {
    const newUserTemp = newUser;

    if(key == "groups"){
      let tempArrayGroups = [];
      val.map((singleGroup)=>{
        tempArrayGroups.push(singleGroup.value)
      })
      val = tempArrayGroups;
    }

    newUserTemp[key] = val;
    setNewUser({...newUserTemp});
  }

  const modalContent = isOpen ? (
   <div>
    <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{t("inviteUserElement.addUsers")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text fontSize={"smaller"} mb={"0"} fontWeight={"bold"}>{t("inviteUserElement.fullName")}</Text>
        <InputGroup  mb="3">
            <InputLeftElement
                pointerEvents='none'
                children={<IoPerson color='#718096' fontSize={"1.3em"}/>}
            />
            <Input value={newUser.username} onChange={evt =>  onChangeText(`username`, evt.target.value)} autoFocus placeholder={t("inviteUserElement.fullNameRequired")} />
        </InputGroup>

        <Text mt="3" fontSize={"smaller"} mb={"0"} fontWeight={"bold"}>{t("inviteUserElement.businessMail")}</Text>
        <InputGroup>
            <InputLeftElement
                pointerEvents='none'
                children={<IoMail color='#718096' fontSize={"1.3em"}/>}
            />
            <Input value={newUser.email} onChange={evt =>  onChangeText(`email`, evt.target.value)}  type="email" placeholder={t("inviteUserElement.businessMail")} />
        </InputGroup>

        <Text mt="3" fontSize={"smaller"} mb={"0"} fontWeight={"bold"}>{t("inviteUserElement.userGroups")}</Text>
        <Select
          size={"sm"}
          placeholder={t("inviteUserElement.selectGroups")}
          options={groupsListOptions}
          isMulti
          onChange={(groupList) => onChangeText("groups",groupList)}
        />
        {/*
        <Select value={newUser.role} onChange={evt =>  onChangeText(`role`, evt.target.value)}>
            <option value='ADMIN'>Admin</option>
            <option value='EDITOR'>Accounting</option>
            <option value='READER'>Employee</option>
        </Select>
        <Button mt="5" onClick={onRolesOpen}>
          <IoInformationCircle style={{marginRight: "5px"}}></IoInformationCircle>
          How to choose a role?
        </Button>*/}
        {!(newUser.email != "" && newUser.username != "" && newUser.groups.length != 0) &&
        <Text style={{color: "red", marginTop: 10}}>
          {errorUserInvite}
        </Text>
        }
        {"cost" in session && session.cost.pricePerUser != "" &&
        <Text fontSize={"small"} style={{color: "grey", marginTop: 10}}>
          {t('inviteUserElement.planCost', { cost: (session.cost.pricePerUser/100), unit: session.cost.subscriptionDuration})}
        </Text>
        }
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose} mr={3}>{t("cancel")}</Button>
        <Button isLoading={isLoadingUserInvite} disabled={!(newUser.email != "" && newUser.username != "" && newUser.groups.length != 0)} colorScheme='rekening.main' style={{color: "black"}} onClick={()=>{createNewUser()}}>
          <IoPersonAdd></IoPersonAdd>
          <Text ml="1">{t("inviteUserElement.addUser")}</Text>
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
</div>
  ) : null;
  
  return modalContent
};

export default InviteUserModal;