import {
    Link as ChakraLink,
    Text,
    Input,
    Box,
    Badge,
    Image,
    SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Select,
    Heading,
    Avatar,
    Grid,
    GridItem,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Skeleton,
    Center,
    Divider,
    Spinner,
    Progress,
    Flex,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
  } from '@chakra-ui/react'

import {IoSettings, IoExit,IoArrowUpCircle,IoCard, IoCheckmarkCircleOutline, IoBriefcase, IoPerson, IoMail,IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle} from 'react-icons/io5';


import React, { useEffect, useState } from "react";
import { useTranslation } from 'next-i18next'
import ReactGA from 'react-ga4'; ReactGA.initialize('G-0GRPP85B5D');

const SubscriptionManager = ({ session, forceDisplay,  isUpgradeOpen, onUpgradeOpen, onUpgradeClose}) => {

    

    const {t} = useTranslation('common')

    const paymentPrices = {
        monthly: {
            business: {
                price: "9.99",
                stripeId: "price_1N1BgEDbs3ThQfLDWZMh9aQE"
            },
            professional: {
                price: "14.99",
                stripeId: "price_1N1Bg7Dbs3ThQfLDRxyrNZgt"
            }},
        annual: {
            business: {
                price: "8.25",
                annualPrice: "99.00",
                stripeId: "price_1N1BgEDbs3ThQfLDtHXIMyNB"
            },
            professional: {
                price: "12.41",
                annualPrice: "149.00",
                stripeId: "price_1N1Bg7Dbs3ThQfLD3rd3ksvx"
            }}
        };

    React.useEffect(() => {
        ReactGA.event({ category: 'Subscription', action: 'Open Subscription Manager' });
    }, )

    const [subscriptionDuration, setSubscriptionDuration] = useState("annual");

    const api_url = process.env.NEXT_PUBLIC_API_URL

    const changeSubscriptionDuration = async (duration) =>{
        setSubscriptionDuration(duration)
    }
    const makeSubscription = async (subscriptionSelected) =>{
    
        ReactGA.event({ category: 'Subscription', action: 'Open Buy Subscription Modal' });
        const fetchData = await fetch(`${api_url}/teams/subscribe/${subscriptionSelected}`, {
          method: "POST",
          headers: {
            'Authorization': `Bearer ${session.accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        const fetchDataJson = await fetchData.json();
        if("statusCode" in fetchDataJson){
        }else{ 
            window.location.href = fetchDataJson.url;
        }
    }

    let subscriptionElement = (
    <Box width={"100%"} >
    <Heading mb="3" size={"lg"}>
        {t("subscriptionManager.subscription")}
    </Heading>

    <Box onClick={onUpgradeOpen}  padding="3" mb="5" backgroundColor="white" borderWidth='1px' borderRadius='lg' style={{transition: "all 0.2s"}}
        _hover={{
        cursor: "pointer",
        backgroundColor: "#f4f4f4"
        }}
    >
        <StatGroup mb="2">
            <Stat>
                <StatLabel>{t("subscriptionManager.subscription")}</StatLabel>
                <StatNumber>{session["team_data"]["subscription"]}</StatNumber>
            </Stat>
            <Stat>
                <StatLabel>{t("subscriptionManager.invoicesManaged")}</StatLabel>
                <StatNumber>{session["usage"]["used"]}</StatNumber>
            </Stat>
            <Stat>
                <StatLabel>{t("subscriptionManager.invoiceLimit")}</StatLabel>
                {session["usage"]["limit"] > 50 &&
                <StatNumber>                            
                    ∞
                </StatNumber>
                }
                {session["usage"]["limit"] <= 50 &&
                <StatNumber>                            
                    50
                </StatNumber>
                }
            </Stat>
            {session["team_data"]["subscription"] == "BASIC" &&
            <Stat>
                <StatLabel>{t("subscriptionManager.percentageUsed")}</StatLabel>
                <StatNumber>{((session["usage"]["used"]/ session["usage"]["limit"])*100).toFixed(0)}%</StatNumber>
            </Stat>
            }
            {session["team_data"]["subscription"] != "BASIC" &&
            <Stat>
                <StatLabel>{t("subscriptionManager.usersInTeam")}</StatLabel>
                <StatNumber>{session["usage"]["users"]}</StatNumber>
            </Stat>
            }
        </StatGroup>
        <Progress colorScheme={"orange"} borderRadius="5px" height='20px' value={(session["usage"]["used"]/ session["usage"]["limit"])*100} />
        </Box>
        <Modal size={"4xl"} onClose={onUpgradeClose} isOpen={isUpgradeOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t("subscriptionManager.upgradePlan")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                {session["team_data"]["subscription"] != "PROFESSIONAL" &&
                <Heading as="h3" size="sm" mt="0" mb="2">
                    {t("subscriptionManager.paymentPeriod")}
                </Heading>
                }
                {(subscriptionDuration == "annual" && session["team_data"]["subscription"] != "PROFESSIONAL") &&
                <Flex gap="5" mb="3">
                    <Button onClick={()=>{changeSubscriptionDuration("annual")}} width={"full"} colorScheme="gray">{t("subscriptionManager.annualPayment20")}</Button>
                    <Button onClick={()=>{changeSubscriptionDuration("monthly")}} width={"full"} 
                        border='2px'
                        borderColor='gray.200'
                        colorScheme='black' 
                        variant="outline"
                        color="gray.700"
                    > 
                        {t("subscriptionManager.monthlyPayment")}
                    </Button>
                </Flex>
                } 
                {(subscriptionDuration == "monthly" && session["team_data"]["subscription"] != "PROFESSIONAL") &&
                <Flex gap="5" mb="3"> 
                    <Button onClick={()=>{changeSubscriptionDuration("annual")}} width={"full"} 
                        border='2px'
                        borderColor='gray.200'
                        colorScheme='black' 
                        variant="outline"
                        color="gray.700"
                    >
                        {t("subscriptionManager.annualPayment20")}
                    </Button>
                    <Button onClick={()=>{changeSubscriptionDuration("monthly")}} width={"full"}  colorScheme="gray" >{t("subscriptionManager.monthlyPayment")}</Button>
                </Flex>
                }
                {session["team_data"]["subscription"] != "PROFESSIONAL" &&
                <Heading as="h3" size="sm" mt="0" mb="2">
                    {t("subscriptionManager.suggestedPlan")}
                </Heading>
                }
                {session["team_data"]["subscription"] != "PROFESSIONAL" &&
                <Box  padding="3" mb="5" backgroundColor="#fff" borderWidth='1px' borderColor={"orange.300"} borderRadius='lg' style={{transition: "all 0.2s"}}
                    _hover={{
                    cursor: "pointer",
                    backgroundColor: "#f4f4f4"
                    }}
                >
                    <SimpleGrid mb={"2"} columns={4} spacing={0}>
                        <GridItem colSpan={2}>
                            <Stat>
                                <StatNumber fontWeight={"bold"}>Professional</StatNumber>
                                <StatHelpText>{t("subscriptionManager.madeForMedium")}</StatHelpText>
                            </Stat>
                            <Flex>
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.upToUsers", {users: "100"})}</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.uptoStorage", {storage: "50"})}</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1" mb="3">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.customBudgets", {budgets: "25"})}</Text>
                                </Box>
                            </Flex>
                            <Divider></Divider>
                            <Stat mt="3">
                                <StatNumber fontSize={"1em"} fontWeight={"bold"}>Total: {((paymentPrices[subscriptionDuration].professional.price)*session["usage"]["users"]).toFixed(2)}€</StatNumber>
                                <StatHelpText mb="0">{t("subscriptionManager.perMonthUser", {user: session["usage"]["users"]})}</StatHelpText>
                            </Stat>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Stat textAlign={"right"}>
                                <StatNumber fontWeight={"bold"}>{paymentPrices[subscriptionDuration].professional.price}€</StatNumber>
                                <StatHelpText>{t("subscriptionManager.perUserMonth")} </StatHelpText>
                            </Stat>
                            <Flex>
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.upToInvoices", {invoices: "100.000"})}</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.upToApprovals", {approvals: "10"})}</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1" mb="3">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.upToGroups", {groups: "10"})}</Text>
                                </Box>
                            </Flex>
                            <Divider></Divider>
                            <Button onClick={()=>{makeSubscription(paymentPrices[subscriptionDuration].professional.stripeId)}} size={"md"} mt="4" width={"full"} colorScheme={"rekening.main"} color="black">
                                <IoArrowUpCircle color="black" />
                                <Text color="black" ml="1">{t("subscriptionManager.upgradePro")}</Text>
                            </Button>
                        </GridItem>
                    </SimpleGrid>
                </Box>
                }

                {session["team_data"]["subscription"] == "BASIC" || session["team_data"]["subscription"] == "STARTER" && 
                <Box  padding="3" mb="5" backgroundColor="white" borderWidth='1px'  borderColor={"gray.200"} borderRadius='lg' style={{transition: "all 0.2s"}}
                    _hover={{
                    cursor: "pointer",
                    backgroundColor: "#f4f4f4"
                    }}
                >
                    <SimpleGrid mb={"2"} columns={4} spacing={5}>
                        <GridItem colSpan={2}>
                            <Stat>
                                <StatNumber fontWeight={"bold"}>Business</StatNumber>
                                <StatHelpText>{t("subscriptionManager.madeForSmall")}</StatHelpText>
                            </Stat>
                            <Flex>
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.upToUsers", {users: "10"})}</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.upToStorage", {storage: "20"})}</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1" mb="3">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.customBudgets", {budgets: "5"})}</Text>
                                </Box>
                            </Flex>
                            <Divider></Divider>
                            <Stat mt="3">
                                <StatNumber fontSize={"1em"} fontWeight={"bold"}>Total: {((paymentPrices[subscriptionDuration].business.price)*session["usage"]["users"]).toFixed(2)}€</StatNumber>
                                <StatHelpText mb="0">{t("subscriptionManager.perMonthUser",{user: session["usage"]["users"]})}</StatHelpText>
                            </Stat>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Stat textAlign={"right"}>
                                <StatNumber fontWeight={"bold"}>{(paymentPrices[subscriptionDuration].business.price)}€</StatNumber>
                                <StatHelpText>{t("subscriptionManager.perUserMonth")}</StatHelpText>
                            </Stat>
                            <Flex>
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.upToInvoices", {invoices: "25.000"})}</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.upToApprovals", {approvals: "5"})}</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1" mb="3">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>{t("subscriptionManager.upToGroups", {groups: "3"})}</Text>
                                </Box>
                            </Flex>
                            <Divider></Divider>
                            <Button  onClick={()=>{makeSubscription(paymentPrices[subscriptionDuration].business.stripeId)}} size={"md"} mt="4" width={"full"} color="black">
                                <IoArrowUpCircle color="black" />
                                <Text color="black" ml="1">{t("subscriptionManager.upgradeBusiness")}</Text>
                            </Button>
                        </GridItem>
                    </SimpleGrid>
                </Box>
                }
                <Divider></Divider>
                <Heading as="h3" size="sm" mt="5" mb="2">
                    {t("subscriptionManager.currentPlan")}
                </Heading>

                <Box  padding="3" mb="5" backgroundColor="white" borderWidth='1px'  borderColor={"gray.200"} borderRadius='lg' style={{transition: "all 0.2s"}}>
                    <StatGroup mb="2">
                        <Stat>
                            <StatNumber>{session["team_data"]["subscription"]}</StatNumber>
                            <StatHelpText>{t("subscriptionManager.subscription")}</StatHelpText>
                        </Stat>
                        <Stat>
                            <StatNumber>{session["usage"]["used"]}</StatNumber>
                            <StatHelpText>{t("subscriptionManager.invoicesManaged")}</StatHelpText>
                        </Stat>
                        <Stat>
                            {session["usage"]["limit"] > 50 &&
                            <StatNumber>                            
                                    ∞
                            </StatNumber>
                            }
                            {session["usage"]["limit"] <= 50 &&
                            <StatNumber>                            
                                    50
                            </StatNumber>
                            }
                            <StatHelpText>{t("subscriptionManager.invoiceLimit")}</StatHelpText>
                        </Stat>
                        {session["cost"]["subscriptionDuration"] == "year" &&
                        <Stat>
                            <StatNumber>{(session["cost"]["pricePerUser"]*session["usage"]["users"])/100}€</StatNumber>
                            <StatHelpText>{t("subscriptionManager.costPerYear")}</StatHelpText>
                        </Stat>
                        }
                    </StatGroup>
                    <Button  onClick={()=>{window.open("http://billing.stripe.com/p/login/aEU3eo8HobBFe7ScMM","_blank")}} size={"md"} mt="4" width={"full"} color="black">
                        <IoCard color="black" />
                        <Text color="black" ml="1">{t("subscriptionManager.managePayments")}</Text>
                    </Button>
                </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
    );

    if((session["team_data"]["subscription"] == "BUSINESS" || session["team_data"]["subscription"] == "PROFESSIONAL") && forceDisplay != true){
        subscriptionElement = (<div></div>)
    }

    return subscriptionElement;
};

export default SubscriptionManager;